
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row, Tab, Tabs } from "vant";
import wx from "weixin-js-sdk";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
})
export default class SpecialSubject extends Mixins(Mixin) {
  public activeOne = true; // 判断16号的数据展示与否
  public activeTwo = false; // 判断17号的数据展示与否

  public activeThree = true; // 判断18号的数据展示与否
  public activeFour = false; // 判断19号的数据展示与否

  public activeFive = true; // 判断20号的数据展示与否
  public activeSix = false; // 判断21号的数据展示与否
  // 16号的点击事件
  clickTopicOne(): void {
    this.activeOne = true;
    this.activeTwo = false;
  }
  // 17号的点击事件
  clickTopicTwo(): void {
    this.activeTwo = true;
    this.activeOne = false;
  }
  // 18号的点击事件
  clickTopicThree(): void {
    this.activeThree = true;
    this.activeFour = false;
  }
  // 19号的点击事件
  clickTopicFour(): void {
    this.activeFour = true;
    this.activeThree = false;
  }
  // 20号的点击事件
  clickTopicFive(): void {
    this.activeFive = true;
    this.activeSix = false;
  }
  // 21号的点击事件
  clickTopicSix(): void {
    this.activeSix = true;
    this.activeFive = false;
  }
  // 5月16
  jumpTopicOne(): void {
    console.log("channel:", this.ChannelModule.channel);
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push("/projectdetail/5410200/857575152791379968");
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${5410200}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${5410200},theaterId/${40},taskId/null`
      );
    }
  }
  // 5月17
  jumpTopicTwo(): void {
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push("/projectdetail/5413900/857669006210785280");
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${5413900}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${5413900},theaterId/${40},taskId/null`
      );
    }
  }
  // 5月18
  jumpTopicThree(): void {
    // this.$router.push("/projectdetail/5414000/857669178865115136");
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push("/projectdetail/5414000/857669178865115136");
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${5414000}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${5414000},theaterId/${40},taskId/null`
      );
    }
  }
  // 5月19
  jumpTopicFour(): void {
    // this.$router.push("/projectdetail/5414100/857669273658167296");
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push("/projectdetail/5414100/857669273658167296");
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${5414100}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${5414100},theaterId/${40},taskId/null`
      );
    }
  }
  // 5月20
  jumpTopicFive(): void {
    // this.$router.push("/projectdetail/5414200/857669377572048896");
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push("/projectdetail/5414200/857669377572048896");
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${5414200}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${5414200},theaterId/${40},taskId/null`
      );
    }
  }
  // 5月21
  jumpTopicSix(): void {
    // this.$router.push("/projectdetail/5414300/857669498596909056");
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push("/projectdetail/5414300/857669498596909056");
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesIndex/performDetail/performDetail?id=${5414300}`,
      });
    } else {
      this.jsBridge(
        "goProDetail",
        `productId/${5414300},theaterId/${40},taskId/null`
      );
    }
  }
}
