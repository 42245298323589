import wx from "weixin-js-sdk";
export function wxShare(
  title: string,
  desc: string,
  imgUrl: string,
  link: string
) {
  wx.ready(() => {
    // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
    wx.updateAppMessageShareData({
      title, // 分享标题
      desc, // 分享描述
      imgUrl, // 分享图标
      link,
    });
    // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
    wx.updateTimelineShareData({
      title, // 分享标题
      imgUrl, // 分享图标
      link,
    });
  });
}
